// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
require('jquery')
global.$ = jQuery;

import "custom-javascript/custom";
import "channels";
import "cocoon";
import "chartkick/chart.js"
import "chosen-js/chosen.jquery.js"
import "chosen-js/chosen.css"

Rails.start()
ActiveStorage.start()

import 'bootstrap/dist/js/bootstrap'
import 'bootstrap/dist/css/bootstrap'
import '../stylesheets/application'
import "@fortawesome/fontawesome-free/js/all"

require("stylesheets/application")
require("../plugins/javascript/jqcloud")
